define(['app'], function (app) {
  const personalisation = function () {
    const component = {};

    component.config = {
      selectors: {
        scrollButton: '[scroll-to-top-button]',
        inputs: '.personalisation_overlayTextInput',
        requiredInputTextBox: '.personalisation_overlayTextInput required',
        inputNumber: 'data-personalisation-input',
        outputs: '.personalisationText',
        element: '[data-component=personalisation]',
        resetButton: '.personalisation_errorMessage_button',
        overlayDataContainer: '.productImageCarousel_personalisationOverlay',
        horizontalInput: '[data-rotation=\'0\']',
        verticalInput: '[data-rotation=\'90\']',
        axisButton: '.personalisation_axis_button',
        defaultAxisButton: '#Vertical',
        horizontalOverlay: '.personalisationText1',
        verticalOverlay: '.personalisationText2',
      },
      dataAttributes: {
        defaultMessage: 'data-default-message',
        personalisationValid: 'data-personalisation-valid',
        inputNumber: 'data-input-number',
        rotation: 'data-rotation',
      },
      publishChannels: {
        validPersonalisation: 'personalisation/message',
        previewPersonalisation: 'personalisation/preview',
      },
      classNames: {
        requiredInput: 'required',
      },
      channels: {
        resizeOutput: 'engraving/resizeOutput',
      },
    };

    component.init = (element) => {
      component.element = element;
      component.inputs = component.element.querySelectorAll(
        component.config.selectors.inputs
      );
      component.requiredInputTextBox = document.querySelector(component.config.selectors.requiredInputTextBox);
      component.scrollButton = component.element.querySelector(
        component.config.selectors.scrollButton
      );
      component.overlayDataContainers = document.querySelectorAll(
        component.config.selectors.overlayDataContainer
      );
      component.horizontalInput = component.element.querySelector(
        component.config.selectors.horizontalInput
      );
      component.verticalInput = component.element.querySelector(
        component.config.selectors.verticalInput
      );
      component.axisButton = component.element.querySelectorAll(
        component.config.selectors.axisButton
      );
      component.defaultAxisButton = component.element.querySelector(
        component.config.selectors.defaultAxisButton
      );
      component.horizontalOverlay = document.querySelector(
        component.config.selectors.horizontalOverlay
      );
      component.verticalOverlay = document.querySelector(
        component.config.selectors.verticalOverlay
      );
      
      component.isValidPersonalisation = false;
      
      component.addEventListeners();
      component.defaultAxisButton && component.vertical();
      app.subscribe(
        component.config.channels.resizeOutput,
        component.handleOutputResizing
      );
      component.isPersonalisationDone();
      return component;
    };

    component.addEventListeners = () => {
      let i = 0;
      component.inputs.forEach((input) => {
        i++;

        input.addEventListener('input', (e) => {
          component.target = e.target;
          component.input = e.target.value;
          component.validate();
          component.checkInputValue();
        });

        component.handleOutputResizing(i);
      });

      component.element.addEventListener('submit', function (e) {
        e.preventDefault();
      });

      component.scrollButton &&
        component.scrollButton.addEventListener('click', component.scrollToTop);

      component.defaultAxisButton &&
        component.axisButton.forEach((axisButton) => {
          axisButton.addEventListener('click', component.axisSelect);
        });
    };

    component.axisSelect = (event) => {
      event.target.value === 'HorizontalMessage'
        ? component.horizontal()
        : component.vertical();
    };

    component.vertical = () => {
      if (component.horizontalInput || component.verticalInput) {
        component.horizontalInput.parentElement.style.display = 'none';
        component.horizontalInput.value = '';
        component.validate();
        component.verticalInput.parentElement.style.display = 'flex';
        component.horizontalOverlay.parentElement.style.display = 'none';
        component.verticalOverlay.parentElement.style.display = 'flex';
        const vRotation = component.element.querySelector(component.config.selectors.verticalInput).attributes[component.config.dataAttributes.rotation].nodeValue;
        component.verticalOverlay.style.rotate = vRotation+'deg';
        component.defaultAxisButton.checked = true;
      }
    };

    component.horizontal = () => {
      if (component.horizontalInput || component.verticalInput) {
        component.horizontalInput.parentElement.style.display = 'flex';
        component.verticalInput.parentElement.style.display = 'none';
        component.verticalInput.value = '';
        component.validate();
        component.verticalOverlay.parentElement.style.display = 'none';
        component.horizontalOverlay.parentElement.style.display = 'flex';
        component.resizeOutput();
      }
    };

    component.handleOutputResizing = (index) => {
      const output = document.querySelector(
        component.config.selectors.outputs + index
      );
      if (output && output.parentElement.style.display !== 'none') {
        setTimeout(() => { component.resizeOutput(); }, 3000);
        window.addEventListener( 'optimizedResize', () => { component.resizeOutput(); },
          false);
        component.throttle('resize', 'optimizedResize');
      }
    };

    component.validate = () => {
      const blacklistSelector = component.element.querySelector('[data-blacklistid]');
      component.blacklistid = blacklistSelector.getAttribute('data-blacklistid');
      app.ajax.get({
        url: `/blacklist.personalisation?value=${encodeURIComponent(
          component.input
        )}&blacklistId=${component.blacklistid}`,
        success: component.validationSuccess,
        error: component.validationError,
      });
      const inputNumber =
        component.target.attributes[component.config.selectors.inputNumber]
          .nodeValue;
      component.updateWordCount(inputNumber, component.target.value.length);
    };

    component.validationSuccess = (result) => {
      result = result == 'false';
      component.errorMessage =
        component.target.nextElementSibling.nextElementSibling;
      if (result) {
        component.errorMessage.style.display = 'none';
        component.editPreviewText();
        component.updatePersonalisationData(true);
      } else {
        component.errorMessage.style.display = 'flex';
        component.resetButton = component.errorMessage.querySelector(
          component.config.selectors.resetButton
        );
        component.resetButton.addEventListener(
          'click',
          component.resetButtonClick
        );
        component.updatePersonalisationData(false);
      }
    };

    component.validationError = () => {
      console.error(
        `Could not validate word ${component.elements.textInput.value}`
      );
      return false;
    };

    component.editPreviewText = () => {
      const inputNumber =
        component.target.attributes[component.config.selectors.inputNumber]
          .nodeValue;
      const output = document.querySelector(
        component.config.selectors.outputs + inputNumber
      );
      if (!output) {
        return;
      }
      if (component.target.value.length === 0) {
        output.innerHTML = output
          .getAttribute(component.config.dataAttributes.defaultMessage)
          .toString();
      } else {
        output.innerHTML = component.target.value;
      }
      component.updateWordCount(inputNumber, component.target.value.length);
      component.resizeOutput();
    };

    component.resetButtonClick = () => {
      component.target.value = '';
      component.editPreviewText();
      component.errorMessage.style.display = 'none';
    };

    component.updateWordCount = (inputNumber, currentLength) => {
      const wordCountElement = component.element.querySelector(
        `.personalisation_chars_${inputNumber}`
      );
      const maxLength = component.element.querySelector(
        `[data-personalisation-input="${inputNumber}"]`
      ).maxLength;
      const remaining = maxLength - currentLength;
      wordCountElement && (wordCountElement.innerHTML = remaining.toString());
    };

    component.resizeOutput = () => {
      const output = component.getLargestOutput();
      const currentFontSize = parseInt(
        window.getComputedStyle(output).fontSize
      );
      let newFontSize = component.getNewFontSize(output);

      if (currentFontSize !== newFontSize && newFontSize > 0) {
        output.style.fontSize = `${newFontSize}px`;
        component.resizeOutput();
      } else {
        const outputs = document.querySelectorAll(
          component.config.selectors.outputs
        );
        outputs.forEach((output) => {
          if(output.parentElement.style.display !== 'none')
            output.style.fontSize = `${newFontSize}px`;
        });
      }
    };

    component.getNewFontSize = (output) => {
      const currentFontSize = parseInt(
        window.getComputedStyle(output).fontSize
      );
      let [textWidth, textHeight] = component.getTextDimensions(
        output,
        currentFontSize
      );
      let parentWidth = output.parentNode.offsetWidth;
      let parentHeight = output.parentNode.offsetHeight;
      (parentHeight > parentWidth) && ([parentWidth, parentHeight] = [parentHeight, parentWidth]);
      const widthScalingFactor = parentWidth / textWidth;
      const heightScalingFactor = parentHeight / textHeight;

      const scalingFactor = Math.min(heightScalingFactor, widthScalingFactor);
      return Math.floor(scalingFactor * currentFontSize);
    };

    component.getLargestOutput = () => {
      const outputs = document.querySelectorAll(
        component.config.selectors.outputs
      );

      let widestOutput;
      let widestWidth;
      outputs.forEach((output) => {
        if (output.parentElement.style.display !== 'none') {
          // eslint-disable-next-line no-unused-vars
          const [textWidth, textHeight] = component.getTextDimensions(output,12);
          if ((widestWidth && widestWidth < textWidth) || !widestWidth) {
            widestOutput = output;
            widestWidth = textWidth;
          }
        }
      });

      return widestOutput;
    };

    component.getTextDimensions = (outputBox, size) => {
      const text = document.createElement('span');
      document.body.appendChild(text);
      text.style.fontFamily = window.getComputedStyle(outputBox).fontFamily;
      text.style.fontSize = `${size}px`;
      text.style.width = 'auto';
      text.style.height = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.lineHeight = '1';
      text.style.letterSpacing = `${parseInt(
        window.getComputedStyle(outputBox).letterSpacing
      )}px`;
      text.innerHTML = outputBox.innerHTML;
      let width = text.offsetWidth;
      document.body.removeChild(text);
      return [width, size];
    };

    component.scrollToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      app.publish(
        component.config.publishChannels.previewPersonalisation, 0
      );
    };

    component.updatePersonalisationData = (validated) => {
      const isValid =
        component.areRequiredInputsFilled() &&
        validated &&
        component.checkInputValue();

      if (isValid !== component.isValidPersonalisation) {
        component.isValidPersonalisation = isValid;
        component.element.setAttribute(
          component.config.dataAttributes.personalisationValid,
          component.isValidPersonalisation
        );
        app.publish(
          component.config.publishChannels.validPersonalisation,
          true,
          component.isValidPersonalisation
        );
      }
    };

    component.areRequiredInputsFilled = () => {
      let areFilled = true;

      component.inputs.forEach((input) => {
        if (
          input.parentElement.style.display !== 'none' &&
          input.classList.contains(component.config.classNames.requiredInput) &&
          input.value.length === 0
        ) {
          areFilled = false;
        }
      });
      return areFilled;
    };

    component.checkInputValue = () => {
      let isValidInput = true;
      const validRegEx = /[^a-z0-9 '`‘’-]/gi;

      component.inputs.forEach((input) => {
        if (validRegEx.test(input.value)) {
          input.value = input.value.replace(validRegEx, '');
          isValidInput = false;
        }
      });
      return isValidInput;
    };

    component.isPersonalisationDone = () => {
      let isValidPersonalisation = false;
      if(component.inputs !== null && component.inputs.length===0){
        isValidPersonalisation = !component.requiredInputTextBox;
      }
      app.publish(
        component.config.publishChannels.validPersonalisation, true, isValidPersonalisation
      );
    }

    component.throttle = function (type, name, comp) {
      comp = comp || window;
      var running = false;
      var func = function () {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function () {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    return component;
  };
  return personalisation;
});
